import React from "react";
import "./Prevention.css";

function Prevention() {
  const preventionServices = [
    {
      title: "Fire Prevention",
      description:
        "Comprehensive fire prevention services including inspections, planning, and risk assessment.",
      icon: "🔥",
    },
    {
      title: "Code Compliance",
      description:
        "Ensuring buildings meet fire safety codes and regulations for community safety.",
      icon: "📋",
    },
    {
      title: "Public Education",
      description:
        "Educational programs and resources to help prevent fires and promote safety.",
      icon: "📚",
    },
    {
      title: "Plan Review",
      description:
        "Expert review of construction plans to ensure fire safety standards.",
      icon: "🏗️",
    },
  ];

  return (
    <div className="prevention-container">
      <div className="prevention-header">
        <h1>Fire Prevention</h1>
        <p className="header-subtitle">
          Protecting Our Community Through Prevention
        </p>
      </div>

      <div className="prevention-intro">
        <p>
          South Summit Fire Protection District provides fire prevention and
          other community risk reduction services through the use of long term
          planning, inspection and plan review, code compliance, public
          information and outreach programs and services.
        </p>
      </div>

      <div className="services-grid">
        {preventionServices.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

      <div className="prevention-message">
        <h2>Community Safety</h2>
        <p>
          One of the most important functions for South Summit Fire Protection
          District is to prevent loss of life and property due to fire. This is
          accomplished through enforcement, engineering and education. All South
          Summit Fire Fighters are trained in the basic aspects of fire
          prevention and conduct regular trainings.
        </p>
        <p>
          Every citizen can become involved in fire prevention and the results
          could save a home, or a life. Fire Prevention is everybody's business.
        </p>
      </div>
    </div>
  );
}

export default Prevention;
