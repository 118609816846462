import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>

      <div className="contact-grid">
        <div className="contact-card emergency">
          <div className="card-icon">🚨</div>
          <h2>Emergency?</h2>
          <p>For emergencies, please call</p>
          <div className="emergency-number">911</div>
        </div>

        <div className="contact-card main-office">
          <div className="card-icon">🏢</div>
          <h2>Main Office</h2>
          <p>South Summit Fire Protection District</p>
          <p>Phone: (435) 783-2375</p>
          <p>Monday - Friday: 8:00 AM - 5:00 PM MST</p>
        </div>

        <div className="contact-card fire-marshal">
          <div className="card-icon">👨‍🚒</div>
          <h2>Fire Marshal</h2>
          <p>Jackson Coleman</p>
          <p>Phone: (435) 962-2884</p>
          <a href="mailto:jcoleman@southsummitfd.gov">
            jcoleman@southsummitfd.gov
          </a>
        </div>
      </div>

      <div className="contact-message">
        <p>
          Thank you for taking the time to reach out. Our staff will respond to
          your comments and suggestions promptly and efficiently.
        </p>
      </div>
    </div>
  );
}

export default Contact;
