import React from "react";
import "./News.css";

function News() {
  const newsItems = [
    {
      id: 1,
      title: "2025 Open Burn Permit Information",
      description:
        "Important information regarding open burn permits for the 2025 season.",
      date: "January 1, 2025",
      type: "Announcement",
      link: "/Burn-Permit-info.pdf",
      icon: "📄",
    },
    // Add more news items here as needed
  ];

  return (
    <div className="news-container">
      <div className="news-header">
        <h1>District News & Updates</h1>
        <p className="header-subtitle">
          Stay informed with the latest news from South Summit Fire Protection
          District
        </p>
      </div>

      <div className="news-grid">
        {newsItems.map((item) => (
          <div key={item.id} className="news-card">
            <div className="news-icon">{item.icon}</div>
            <div className="news-content">
              <span className="news-type">{item.type}</span>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              <div className="news-meta">
                <span className="news-date">{item.date}</span>
              </div>
            </div>
            <div className="news-footer">
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="view-button"
              >
                View PDF
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default News;
