import React from "react";
import "./Public Records.css";

function PublicRecordsRequest() {
  const gramaDocuments = [
    {
      title: "GRAMA Request Form",
      file: "/GRAMA_Request_Form.pdf",
      type: "Form",
    },
    {
      title: "GRAMA Request and Billing Information",
      file: "/GRAMA_Billing_Information.pdf",
      type: "Form",
    },
  ];

  return (
    <div className="records-request-section">
      <h2 className="public-records-title">Public Records Request</h2>
      <div className="request-info">
        <p>
          To request public records from South Summit Fire District, please
          contact:
        </p>
        <div className="contact-details">
          <p>
            <strong>Records Officer:</strong> Melanie Mitchell
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:mmitchell@southsummitfd.gov">
              mmitchell@southsummitfd.gov
            </a>
          </p>
          <p>
            <strong>Phone:</strong> (435) 783-2375
          </p>
          <p>
            <strong>Office Hours:</strong> Monday - Friday, 8:00 AM - 5:00 PM
            MST
          </p>
        </div>
        <div className="request-instructions">
          <p>GRAMA Request and Billing Information</p>
          <div className="grama-documents-grid">
            {gramaDocuments.map((doc, index) => (
              <div key={index} className="document-card">
                <div className="document-icon">📄</div>
                <div className="document-info">
                  <h3>{doc.title}</h3>
                  <p className="document-type">{doc.type}</p>
                </div>
                <a
                  href={doc.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                >
                  View PDF
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="insurance-information">
          <p>Insurance Information Letter</p>
          <div className="document-card single-document">
            <div className="document-icon">📄</div>
            <div className="document-info">
              <h3>Insurance Information Letter</h3>
              <p className="document-type">Letter</p>
            </div>
            <a
              href="/Insurance_Information_Letter.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              View PDF
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicRecordsRequest;
