import React from "react";
import "./Recruitment & Hiring.css";

function RecruitmentHiring() {
  const positions = [
    {
      title: "Firefighter/EMT",
      type: "Full-Time",
      status: "Closed",
      description:
        "Looking for dedicated individuals to join our team as Firefighter/EMT.",
      requirements: [
        "Valid Utah EMT certification",
        "Firefighter I & II certification",
        "Valid driver's license",
        "Must be 18 years or older",
      ],
    },
  ];

  return (
    <div className="recruitment-container">
      <div className="recruitment-header">
        <h1>Join Our Team</h1>
        <p className="header-subtitle">
          Make a difference in your community with South Summit Fire
        </p>
      </div>

      <div className="career-intro">
        <h2>Career Opportunities</h2>
        <p>
          South Summit Fire Protection District is seeking motivated individuals
          who are committed to serving their community through excellence in
          firefighting, emergency medical services, and public safety.
        </p>
      </div>

      <div className="positions-grid">
        {positions.map((position, index) => (
          <div key={index} className="position-card">
            <div className="position-header">
              <h3>{position.title}</h3>
              <div className="position-badges">
                <span className="badge type">{position.type}</span>
                <span className="badge status">{position.status}</span>
              </div>
            </div>
            <p className="position-description">{position.description}</p>
            <div className="requirements-section">
              <h4>Requirements:</h4>
              <ul>
                {position.requirements.map((req, i) => (
                  <li key={i}>{req}</li>
                ))}
              </ul>
            </div>
            <div className="position-footer">
              <button className="apply-button">Apply Now</button>
            </div>
          </div>
        ))}
      </div>

      <div className="benefits-section">
        <h2>Why Join Us?</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <div className="benefit-icon">💪</div>
            <h3>Career Growth</h3>
            <p>Continuous training and advancement opportunities</p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon">🏥</div>
            <h3>Benefits Package</h3>
            <p>Comprehensive health, dental, and retirement benefits</p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon">👥</div>
            <h3>Team Environment</h3>
            <p>Work with dedicated professionals in a supportive atmosphere</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruitmentHiring;
