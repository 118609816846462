import React from "react";
import "./Education.css";

function Education() {
  const safetyTips = [
    {
      title: "Create an Escape Plan",
      description:
        "Draw a map of each level of your home and show all doors and windows. Have two ways out of each room.",
      icon: "🏠",
    },
    {
      title: "Emergency Equipment",
      description:
        "Only purchase collapsible escape ladders evaluated by a recognized testing laboratory. Use the ladder only in a real emergency.",
      icon: "🪜",
    },
    {
      title: "Child Safety",
      description:
        "Teach children how to escape on their own in case you cannot help them.",
      icon: "👶",
    },
    {
      title: "Accessibility Planning",
      description:
        "Have a plan for everyone in your home who has a disability.",
      icon: "♿",
    },
  ];

  return (
    <div className="education-container">
      <div className="education-header">
        <h1>Fire Safety Education</h1>
        <p className="header-subtitle">Knowledge is Your Best Defense</p>
      </div>

      <div className="safety-intro">
        <h2>Fire Prevention Tips – Have 2 Ways Out</h2>
        <p>
          In the event of a fire, remember that every second counts, so you and
          your family must always be prepared. Escape plans help you get out of
          your home quickly. In less than 30 seconds, a small flame can get
          completely out of control and turn into a major fire. It only takes
          minutes for a house to fill with thick black smoke and become engulfed
          in flames.
        </p>
      </div>

      <div className="safety-tips-grid">
        {safetyTips.map((tip, index) => (
          <div key={index} className="safety-card">
            <div className="tip-icon">{tip.icon}</div>
            <h3>{tip.title}</h3>
            <p>{tip.description}</p>
          </div>
        ))}
      </div>

      <div className="practice-section">
        <h2>Practice Your Plan</h2>
        <p>
          Prepare and practice your fire escape plan twice a year with everyone
          in your household, including children and people with disabilities.
          It's also a good idea to practice your plan with overnight guests.
        </p>
      </div>
    </div>
  );
}

export default Education;
