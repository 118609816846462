import React from "react";
import "./Resources.css";

function Resources() {
  const stations = [
    {
      id: 41,
      name: "Station 41",
      location: "Kamas Station",
      equipment: ["Engine 41", "Ambulance 41", "Brush 41", "Heavy Rescue 41"],
    },
    {
      id: 42,
      name: "Station 42",
      location: "Oakley Station",
      equipment: ["Engine 42", "Ambulance 42", "Brush 42"],
    },
    {
      id: 43,
      name: "Station 43",
      location: "Woodland Station",
      equipment: ["Engine 43", "Ambulance 43", "Brush 43"],
    },
  ];

  return (
    <div className="resources-container">
      <div className="resources-header">
        <h1>Our Resources</h1>
        <p>
          Serving the community with state-of-the-art equipment and facilities
        </p>
      </div>

      <div className="stations-grid">
        {stations.map((station) => (
          <div key={station.id} className="station-card">
            <div className="station-header">
              <h2>{station.name}</h2>
              <p className="location">{station.location}</p>
            </div>
            <div className="equipment-list">
              <h3>Equipment</h3>
              <ul>
                {station.equipment.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="resources-footer">
        <div className="info-box">
          <h3>Emergency Response</h3>
          <p>
            Our stations are strategically located to provide rapid response
            throughout the district.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Resources;
