import React, { useState } from "react";
import "./Public Records.css";
import PublicRecordsRequest from "./Public Records Request";

function PublicRecords() {
  const [activeTab, setActiveTab] = useState("minutes");

  const documents = [
    {
      title: "Business Meeting Agenda August 21, 2024",
      file: "/8 AUGUST 2024 REGULAR MEETING.pdf",
      type: "Agenda",
    },
    {
      title: "August 21, 2024",
      file: "/8 AUGUST 2024.pdf",
      type: "Minutes",
    },
    {
      title: "SEPTEMBER 17, 2024 Work Meeting",
      file: "/9 SEPTEMBER 2024 Work Meeting.pdf",
      type: "Work Meeting",
    },
    {
      title: "SEPTEMBER 17, 2024 REGULAR MEETING",
      file: "/9 SEPTEMBER 2024 REGULAR MEETING.pdf",
      type: "Regular Meeting",
    },
  ];

  return (
    <div className="public-records-container">
      <div className="public-records-nav">
        <button
          className={`nav-button ${activeTab === "minutes" ? "active" : ""}`}
          onClick={() => setActiveTab("minutes")}
        >
          Agenda and Meeting Minutes
        </button>
        <button
          className={`nav-button ${activeTab === "request" ? "active" : ""}`}
          onClick={() => setActiveTab("request")}
        >
          Public Records Request
        </button>
      </div>

      {activeTab === "minutes" ? (
        <>
          <h2 className="public-records-title">Agenda and Meeting Minutes</h2>
          <div className="documents-grid">
            {documents.map((doc, index) => (
              <div key={index} className="document-card">
                <div className="document-icon">📄</div>
                <div className="document-info">
                  <h3>{doc.title}</h3>
                  <p className="document-type">{doc.type}</p>
                  <p className="document-date">{doc.date}</p>
                </div>
                <a
                  href={doc.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                >
                  View PDF
                </a>
              </div>
            ))}
          </div>
        </>
      ) : (
        <PublicRecordsRequest />
      )}
    </div>
  );
}

export default PublicRecords;
