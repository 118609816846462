import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <div className="about-header">
        <h1>South Summit Fire Protection District</h1>
      </div>

      <div className="about-content">
        <section className="about-section">
          <h2>About Us</h2>
          <p>
            South Summit Fire Protection District provides fire protection,
            emergency medical services and other emergency responses for Kamas,
            Francis, Marion, Oakley, Peoa, Woodland and the Unincorporated
            Areas.
          </p>
          <div className="contact-info">
            <p>102 Thorn Creek Drive Kamas, UT 84036</p>
            <p>Mailing: PO Box 1030 Kamas, UT 84036</p>
          </div>
        </section>

        <section className="about-section">
          <h2>Our History & Expertise</h2>
          <p>
            Throughout the department's history, members have worked to enhance
            fire service and improve service delivery to the residents of the
            Kamas Valley. The department specializes in the response of HazMat,
            Heavy Rescue and Wildland Teams.
          </p>
        </section>

        <section className="about-section">
          <h2>Leadership & Operations</h2>
          <p>
            South Summit Fire Protection District operates under the direction
            of its commissioners. These members are directly appointed by the
            community they represent giving the community local control. The
            Fire Chief and Assistant Chief serve under the direction of the
            board of commissioners and oversees all the day-to-day operations of
            the district.
          </p>
        </section>

        <section className="about-section">
          <h2>Service Impact</h2>
          <div className="stats-container">
            <div className="stat-item">
              <h3>300-400</h3>
              <p>Annual Emergency Calls</p>
            </div>
            <div className="stat-item">
              <h3>3</h3>
              <p>Fire Stations</p>
            </div>
            <div className="stat-item">
              <h3>25+</h3>
              <p>Dedicated Professionals</p>
            </div>
          </div>
        </section>

        <section className="mission-section">
          <h2>Our Mission</h2>
          <blockquote>
            "To promptly and professionally serve the needs of our community
            with pride, dedication, and excellence never failing to deliver
            quality emergency life safety service with the highest level of
            integrity, commitment, teamwork, and community engagement."
          </blockquote>
        </section>
      </div>
    </div>
  );
}

export default About;
