import React, { useState, useEffect } from "react";
import "./Header.css";
import logo from "../assets/ssfd-logo.png";
import { useNavigate, Link, useLocation } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  // Close menu when location changes (user navigates to a new page)
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  // Function to handle link clicks
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-container">
          <img
            src={logo}
            alt="SSFD Logo"
            className="logo"
            onClick={() => {
              navigate("/");
              setIsMenuOpen(false);
            }}
          />
        </div>

        <button
          className="menu-toggle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-expanded={isMenuOpen}
        >
          <span className="hamburger"></span>
        </button>

        <nav className={`nav-menu ${isMenuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <Link to="/home" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleLinkClick}>
                About
              </Link>
            </li>
            <li className="dropdown">
              <span>Services</span>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/resources" onClick={handleLinkClick}>
                    Resources
                  </Link>
                </li>
                <li>
                  <Link to="/prevention" onClick={handleLinkClick}>
                    Prevention
                  </Link>
                </li>
                <li>
                  <Link to="/education" onClick={handleLinkClick}>
                    Education
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/permits" onClick={handleLinkClick}>
                Permits
              </Link>
            </li>
            <li>
              <Link to="/news" onClick={handleLinkClick}>
                News
              </Link>
            </li>
            <li>
              <Link to="/public-records" onClick={handleLinkClick}>
                Public Records
              </Link>
            </li>
            <li>
              <Link to="/recruitment-and-hiring" onClick={handleLinkClick}>
                Careers
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleLinkClick}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
